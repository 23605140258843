import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import AINavbar from '../AINavbar/index';
import UserContext from "../../UserContext";
import { isLoggedIn } from "../../utils";
import LoginPrompt from "../LoginPrompt";
import LeftPane from "../LeftPane/LeftPane";
import { useMediaQuery, createTheme, Box, Button, Card, CardContent, Typography } from '@mui/material';
import ReactGA from "react-ga4";
import Footer from '../Footer/Footer';
import OnScreenMenu from '../OnScreenMenu';
import {Circles} from "react-loader-spinner";
import './index.css';

const SummaryBusinessDashboard = () => {
  // const navigate = useNavigate();
  const [ setLoggedInUserDetails] = useContext(UserContext);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });



  return (
    <>
      {isMobile ? (
        <LeftPane Component={SummaryBusiness} />
      ) : (
        <>
          <AINavbar />
          <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <div style={{ marginTop: '78px' }}>
              <SummaryBusiness />
            </div>
          </Box>
        </>
      )}
    </>
  );
};

const SummaryBusiness = () => {
  const [content, setContent] = useState("");
  const [pyqData, setPyqData] = useState(null);
  const [flashcardData, setFlashcardData] = useState(null);
  const [summaryData, setSummaryData] = useState(null);
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [loadingPyq, setLoadingPyq] = useState(false);
  const [loadingFlashcard, setLoadingFlashcard] = useState(false);
  const [error, setError] = useState(null);
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);

  const fetchData = async () => {
    if (!loggedInUserDetails) {
      setOpenLoginPrompt(true);
      return;
    }

    setLoadingSummary(true);
    setLoadingPyq(true);
    setLoadingFlashcard(true);
    setError(null);
    try {
      const summaryResponse = await axios.post('https://collectorbabu.com/api/business/summary', {
        user_id: loggedInUserDetails.user_id,
        content: content,
      });
      setSummaryData(summaryResponse.data);

      const summaryDataNotes = summaryResponse.data.summary_notes;

      const pyqResponse = await axios.post('https://collectorbabu.com/api/business/pyq', {
        user_id: loggedInUserDetails.user_id,
        content: summaryDataNotes,
      });
      setPyqData(pyqResponse.data);

      const flashcardResponse = await axios.post('https://collectorbabu.com/api/business/flashcards', {
        user_id: loggedInUserDetails.user_id,
        content: summaryDataNotes,
      });
      setFlashcardData(flashcardResponse.data);

    } catch (error) {
      setError("Error fetching data");
    } finally {
      setLoadingSummary(false);
      setLoadingPyq(false);
      setLoadingFlashcard(false);
    }
  };

  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  // const handleLoginPromptOpen = () => {
  //   setOpenLoginPrompt(true);
  // };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };

  const formatSummaryNotes = (notes) => {
    return notes.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>').replace(/\n/g, '<br />');
  };

  return (
    <>
      <div className="summary-business-container">
        <div className="summary-business-form">
          <textarea
            className="summary-business-textarea"
            rows={4}
            value={content}
            onChange={(e) => setContent(e.target.value)}
          />
          <Button className="summary-business-button" variant="contained" color="primary" onClick={fetchData} disabled={loadingSummary || loadingPyq || loadingFlashcard}>
            {loadingSummary || loadingPyq || loadingFlashcard ? 'Loading...' : 'Fetch Data'}
          </Button>
          {error && <Typography color="error">{error}</Typography>}
         {summaryData && (  <div style={{ marginTop: '20px', overflowY: 'auto', height: '500px' }}>
          <Typography variant="h5" gutterBottom>
                  Summary Notes
                </Typography>
          {loadingSummary ? 
          (
            <Circles type="ThreeDots" color="#00BFFF" height={80} width={80} />
          ) : (
            <Card variant="outlined" className="summary-notes-card">
              <CardContent>
             
              <Box className="summary-notes-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: formatSummaryNotes(summaryData.summary_notes),
                  }}
                />
              </Box>

              </CardContent>
            </Card>
          )}
          </div>
        )}



        
        {pyqData && (
          
          <div style={{ marginTop: '20px', overflowY: 'auto', height: '500px' }}>
          <Typography variant="h5" gutterBottom>
                PYQ Data
              </Typography>
          {loadingPyq ? (
            <Circles type="ThreeDots" color="#00BFFF" height={80} width={80} />
          ) : (
            <div>
<h1 style={{ marginTop: '20px' , fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>Prelims</h1>
            
            {pyqData && pyqData.prelims.slice(0, 5).map((question, index) => (
  <Card key={index} variant="outlined" className="flashcard">
    <CardContent>
      <Typography variant="h6">Year: {question.yearAsked}</Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br />') }}></Typography>
      {question.options && (
        <>
          <Typography variant="body2">Options: {question.options.join(', ')}</Typography>
          <Typography variant="body2">Correct Answer: {question.options[question.correctChoiceIndex]}</Typography>
        </>
      )}
    </CardContent>
  </Card>
))}
<h1 style={{ marginTop: '20px' , fontWeight: 'bold', fontSize: '20px', textAlign: 'center'}}>Mains</h1>
{pyqData && pyqData.mains.slice(0, 5).map((question, index) => (
  <Card key={index} variant="outlined" className="flashcard">
    <CardContent>
      <Typography variant="h6">Year: {question.yearAsked}</Typography>
      <Typography variant="body1" dangerouslySetInnerHTML={{ __html: question.question.replace(/\n/g, '<br />') }}></Typography>
      {question.options && (
        <>
          <Typography variant="body2">Options: {question.options.join(', ')}</Typography>
          <Typography variant="body2">Correct Answer: {question.options[question.correctChoiceIndex]}</Typography>
        </>
      )}
    </CardContent>
  </Card>
))}


            </div>
          )}
          </div>
        )}


          {flashcardData && (
            
          <div style={{ marginTop: '20px', overflowY: 'auto', height: '500px' }}>
          <Typography variant="h5" gutterBottom>
                Flashcard Data
              </Typography>
          {loadingFlashcard ? (
            <Circles type="ThreeDots" color="#00BFFF" height={80} width={80} />
          ) :  (
            <div>
              
              {flashcardData.flashcards.map((flashcard, index) => (
                <Card key={index} variant="outlined" className="flashcard">
                  <CardContent>
                    <Typography variant="h6" className="flashcard-question">
                      {flashcard.prelims_question}
                    </Typography>
                    <ul className="knowledge-pointers">
                      {flashcard.knowledge_pointers.map((pointer, idx) => (
                        <li key={idx}>
                          <Typography variant="body2">{pointer}</Typography>
                        </li>
                      ))}
                    </ul>
                  </CardContent>
                </Card>
              ))}
            </div>
          )}
          </div>
        )}
        </div>
      </div>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
      <OnScreenMenu />
      <Footer />
    </>
  );
};

export default SummaryBusinessDashboard;
