import axios from "axios";
import { apiData } from "./apis";
import configData from "./config.json";
import dayjs from "dayjs";
import Cookies from "js-cookie";
// get the SOURCES from to the config to map to the sources
const SOURCES = configData["sources"];
const token = "87957bdf-8fb3-4afc-a5c7-4ad62f785a60";

async function getUserWidgetActions(loggedInUserDetails) {
  try {
    let responseForUserWidgetActions = await axios({
      method: "post",
      url: apiData.getUserWidgetActions,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        user_id: loggedInUserDetails.user_id,
      },
    });

    return responseForUserWidgetActions.data;
  } catch (error) {}
}

async function getWidgetContent(widgetName, loggedInUserDetails, contentRequestDate) {
  try {
    if (dayjs().format("YYYY-MM-DD") === contentRequestDate) {
      contentRequestDate = null;
    }

    let response = await axios({
      method: "post",
      url: apiData.getWidgetContent,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        env: "prod",
        max_links: 10,
        widget_name: widgetName,
        user_id: loggedInUserDetails ? loggedInUserDetails.user_id : null,
        content_request_date: contentRequestDate ? contentRequestDate : null,
      },
    });
    return response.data;
  } catch (error) {}
}

async function getWidgetNames(loggedInUserDetails) {
  try {
    // let widgetNames = [];

    let response = await axios({
      method: "post",
      url: apiData.getWidgetNames,
      "Content-Type": "application/json",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        env: "prod",
        user_id: loggedInUserDetails ? loggedInUserDetails.user_id : null,
      },
    });

    return response.data;
  } catch (error) {}
}

async function getUserRemovedRules(loggedInUserDetails) {
  try {
    // get removed rules
    let response = await axios({
      method: "post",
      url: apiData.getUserRemovedRules,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        user_id: loggedInUserDetails.user_id,
      },
    });

    return response.data;
  } catch (error) {}
}

async function editWidgetSource(widgetName, addOrRemove, rules, loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.editWidgetSource,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        user_id: loggedInUserDetails.user_id,
        add_or_remove: addOrRemove,
        widget_name: widgetName.cardTitle.split(" ").join("_"),
        rules: rules,
      },
    });

    return response;
  } catch (error) {}
}

async function undoUserWidgetActions(ref, action, loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.undoUserWidgetActions,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        user_id: loggedInUserDetails.user_id,
        content_id: ref,
        user_action: action,
      },
    });

    return response;
  } catch (error) {}
}

async function getWidgetContentDetails(refs) {
  try {
    let readLaterDetails = await axios({
      method: "post",
      url: apiData.getWidgetContentDetails,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: {
        refs: refs,
      },
    });

    return readLaterDetails.data;
  } catch (error) {}
}

async function loginViaSso(data) {
  try {
    let config = {
      method: "post",
      url: "https://collectorbabu.com/api/user/login_via_sso",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    };
     await axios(config);
  } catch (error) {}
}

function sortWidgetNamesInDisplayOrder(widgetData) {
  try {
    // get widget names

    let responseWidgetNames = widgetData;
    let widgetNamesWithOrder = [];

    for (let data of responseWidgetNames) {
      widgetNamesWithOrder.push([data.name, data.order]);
    }
    widgetNamesWithOrder = widgetNamesWithOrder.sort((a, b) => {
      return a[1] - b[1];
    });

    let widgetNames = [];
    for (let widget of widgetNamesWithOrder) {
      widgetNames.push(widget[0]);
    }

    return widgetNames;
  } catch (error) {}
}

async function getMapOfUserWidgetFunction(loggedInUserDetails, contentRequestDate) {
  try {
    let mapOfUserWidgetActions = new Map();
    // during first time render if nothing is selected on the calendar then contentRequestDate is undefined
    // in that case set it to current current day
    if (contentRequestDate === undefined) {
      contentRequestDate = dayjs().format("YYYY-MM-DD");
    }
    let countWhichGotRemoved = 0;
    if (loggedInUserDetails) {
      let responseForUserWidgetActions = await getUserWidgetActions(loggedInUserDetails);

      for (let response of responseForUserWidgetActions) {
        // only consider created_at equal to current day
        if (dayjs().format("YYYY-MM-DD") !== dayjs(response.created_at).format("YYYY-MM-DD")) {
          //
          countWhichGotRemoved = countWhichGotRemoved + 1;
          continue;
        }

        if (!mapOfUserWidgetActions.has(response.content_id)) {
          mapOfUserWidgetActions.set(response.content_id, []);
        }
        let arr = mapOfUserWidgetActions.get(response.content_id);
        if (arr.indexOf(response.user_action) < 0) {
          arr.push(response.user_action);
          mapOfUserWidgetActions.set(response.content_id, arr);
        }
      }
    }

    return mapOfUserWidgetActions;
  } catch (error) {}
}

async function getDataForWidgets(widgetNames, mapOfUserWidgetActions, loggedInUserDetails, contentRequestDate) {
  try {
    let finalData = {};
    for (let widget of widgetNames) {
      let widgetData = await getWidgetContent(widget, loggedInUserDetails, contentRequestDate);
      let articlesData = [];

      for (let data of widgetData) {
        let { link, source, title, pub_date, ref, rule } = data;
        pub_date = dayjs(pub_date).format("D MMM");

        // get the image of the row
        let imgSrc = "";

        // can optimise this to not run in for loop instead just get value from object
        for (let [key, value] of Object.entries(SOURCES)) {
          if (source.toLowerCase().split(" ").join("").indexOf(key) > -1) {
            imgSrc = value.logo_path;
            source = value.name;
            break;
          }
        }
        if (link.indexOf("youtube") > -1) {
          imgSrc = SOURCES["youtube"]["logo_path"];
        }

        let userActions = mapOfUserWidgetActions.get(ref) || [];

        // don't push data in the widget if user removed that content from feed
        // this is different from edit widget source
        if (userActions.indexOf("remove_from_feed") > -1) {
          continue;
        }
        articlesData.push({
          source: source,
          link: link,
          imgSrc: imgSrc,
          title: title,
          pub_date: pub_date,
          ref: ref,
          rule: rule,
          markDone: userActions.indexOf("mark_as_read") > -1 ? true : false,
          savedLater: userActions.indexOf("read_later") > -1 ? true : false,
        });
      }

      let temp = {};
      // Find out Why the fuck does the fuckign break if you add widget name in as temp[widget]
      let displayWidgetName = widget.split("_").join(" ");
      temp[displayWidgetName] = {
        articles: articlesData.slice(),
      };

      finalData[displayWidgetName] = temp[displayWidgetName];
    }

    return finalData;
  } catch (error) {}
}

async function populateWidgetState(loggedInUserDetails, contentRequestDate) {
  try {
    let widgetNames = await getWidgetNames(loggedInUserDetails);
    let sortedWidgetNames = sortWidgetNamesInDisplayOrder(widgetNames);

    let mapOfUserWidgetActions = new Map();

    if (loggedInUserDetails.name) {
      mapOfUserWidgetActions = await getMapOfUserWidgetFunction(loggedInUserDetails, contentRequestDate);
    }

    let finalData = await getDataForWidgets(sortedWidgetNames, mapOfUserWidgetActions, loggedInUserDetails, contentRequestDate);

    // make a set here just in case there are duplicated refs
    let setOfAvailableRefsForThisDay = new Set();
    let countOfActionPerformedByUser = 0;

    Object.keys(finalData).forEach((widgetName) => {
      for (let article of finalData[widgetName]["articles"]) {
        setOfAvailableRefsForThisDay.add(article.ref);

        if (mapOfUserWidgetActions.has(article.ref)) {
          countOfActionPerformedByUser++;
        }
      }
    });
    // deduct points for undo actions

    let progress = Math.floor((countOfActionPerformedByUser / setOfAvailableRefsForThisDay.size) * 100);
    return [finalData, progress];
  } catch (error) {}
}

async function populateEditWidgetModalData(widgetName, loggedInUserDetails) {
  try {
    // dont pass loggedin user detaill and content date here as we want to display all sources
    let widgetData = await getWidgetContent(widgetName, null, null);
    let articlesData = [];
    let finalData = {};

    for (let data of widgetData) {
      let { link, source, title, pub_date, ref, rule } = data;
      pub_date = dayjs(pub_date).format("D MMM");

      let imgSrc = "";

      // can optimise this to not run in for loop instead just get value from object
      for (let [key, value] of Object.entries(SOURCES)) {
        if (source.toLowerCase().split(" ").join("").indexOf(key) > -1) {
          imgSrc = value.logo_path;
          source = value.name;
          break;
        }
      }
      if (link.indexOf("youtube") > -1) {
        imgSrc = SOURCES["youtube"]["logo_path"];
      }

      articlesData.push({
        source: source,
        link: link,
        imgSrc: imgSrc,
        title: title,
        pub_date: pub_date,
        ref: ref,
        rule: rule,
        checked: true,
        removed: false,
      });
    }

    let temp = {};
    let widgetDisplayName = widgetName.split("_").join(" ");
    temp[widgetDisplayName] = {
      articles: articlesData.slice(),
      cardTitle: widgetDisplayName,
    };

    finalData[widgetDisplayName] = temp[widgetDisplayName];

    let userRemovedRules = await getUserRemovedRules(loggedInUserDetails);

    // making a set here cause user_removed_rules can return dupes
    let setOfRemovedRules = new Set();
    for (let rules of userRemovedRules) {
      setOfRemovedRules.add(rules);
    }

    let index = 0;
    for (let article of finalData[widgetDisplayName].articles) {
      if (setOfRemovedRules.has(article.rule)) {
        finalData[widgetDisplayName].articles[index].removed = true;
        finalData[widgetDisplayName].articles[index].checked = false;
      }
      index++;
    }

    return finalData[widgetDisplayName];
  } catch (error) {}
}

async function performUserWidgetAction(action, ref, widgetName, loggedInUserDetails) {
  try {
    let data = {
      user_id: loggedInUserDetails.user_id,
      content_id: ref,
      widget_name: widgetName.toLowerCase().split(" ").join("_"),
      user_action: action,
    };

    let response = await axios({
      method: "post",
      url: apiData.performUserWidgetAction,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });

    return response;
  } catch (error) {}
}

async function readerParse(url, loggedInUserDetails) {
  try {
    let data = {
      user_id: loggedInUserDetails.user_id,
      // user_id: "63bc4103400b6442eef3e6cf",
      url: url,
    };

    let response = await axios({
      method: "post",
      url: apiData.readerParse,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });

    return response;
  } catch (error) {}
}

async function getPyqQuestions(data) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.pyqMatch,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });

    return response;
  } catch (error) {}
}

async function mainsAnswerReview(formData, user_id, text, question_id, showManualQuestionUpload, manualQuestion) {
  try {
    let params = {
      user_id: user_id,
    };

    // if (time_taken.length > 0) {
    //   params["time_taken"] = Number(time_taken);
    // }

    if (showManualQuestionUpload) {
      params["question_text_custom"] = manualQuestion;
    }

    if (question_id) {
      params["question_id"] = question_id;
    }

    if (!formData) {
      params["text"] = text;
      formData = new FormData();
      formData.append(`img_file1`, "");
      formData.append(`img_file2`, "");
      formData.append(`img_file3`, "");
    }

    // console.log("in the api call : FORM data: ", formData, " User_id: ", user_id, " url :", apiData.mainsAnswerReview);

    let response = await axios.post(apiData.mainsAnswerReview, formData, {
      params: params,
      headers: {
        accept: "appication/json",
        "Content-Type": "multipart/form-data",
        token: token,
      },
    });
    // console.log("IN the api call response: ", response);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function questionsForDay(day) {
  try {
    let data = {
      day: Number(day),
    };

    let response = await axios({
      method: "post",
      url: apiData.questionsForDay,
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      data: data,
    });

    return response;
  } catch (error) {}
}

async function pay(loggedInUserDetails, planName, year, deviceOS, target_app, ) {
  try {
    let data = {
      user_id: loggedInUserDetails.user_id,
      source: "collectorbabu",
      plan: planName,
      year: parseInt(year)
    };
    console.log(data);
    if (deviceOS) {
      data["deviceOS"] = deviceOS;
      data["target_app"] = target_app;
    }
    let result = await getReferralCode(loggedInUserDetails);

    data["applied_referral"] = result.data.applied_referral_code;

    let response = await axios({
      method: "post",
      url: apiData.pay,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      data: data,
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    return error;
  }
}

async function paymentStatus(merchantTransactionId, loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.paymentStatus,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
      },
      data: {
        user_id: loggedInUserDetails.user_id,
        merchantTransactionId: merchantTransactionId,
        source: "collectorbabu",
      },
    });

    return response.data;
  } catch (error) {
    return error;
  }
}

async function getReferralStats(loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.getReferralStats,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
      data: {
        user_id: loggedInUserDetails.user_id,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}

async function getReferralCode(loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.getReferralCode,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
      data: {
        user_id: loggedInUserDetails.user_id,
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}

async function applyReferralCode(loggedInUserDetails, applied_referral_code) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.applyReferralCode,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
      data: {
        user_id: loggedInUserDetails.user_id,
        applied_referral_code: applied_referral_code,
      },
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
}

const isLoggedIn = async () => {
  let name = Cookies.get("user_name");
  let user_id = Cookies.get("user_id");
  let email = Cookies.get("email");

  if (name && user_id && email) {
    let apiData = {
      name: name,
      user_id: user_id,
      email: email,
      domain: "collectorbabu",
    };

    let userData = await internalLogin(apiData, undefined, false);
    return userData;
  }
};

const internalLogin = async (data, userInfo, setCookie = false) => {
  try {
    let config = {
      method: "post",
      url: apiData.loginViaSso,
      headers: {
        token: "87957bdf-8fb3-4afc-a5c7-4ad62f785a60",
        "Content-Type": "application/json",
      },
      data: data,
    };

    let response = await axios(config);
    // console.log(
    //   "BRUH ",
    //   response.data,
    //   JSON.stringify(Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: response.data.user_id, premium: response.data.premium }))
    // );
    return Object.assign({}, JSON.parse(Cookies.get("google_info")), { user_id: response.data.user_id, premium: response.data.premium, plan: response.data.plan });
  } catch (error) {
    throw new Error(error);
  }
};

async function getPaymentAmount(loggedInUserDetails) {
  try {
    let response = await axios({
      method: "post",
      url: apiData.getPaymentAmount,
      headers: {
        accept: "application/json",
        "Content-Type": "application/json",
        token: token,
      },
      data: {
        user_id: loggedInUserDetails.user_id,
        domain: "collectorbabu",
      },
    });

    return response;
  } catch (error) {
    return error;
  }
}

export {
  getWidgetContentDetails,
  loginViaSso,
  undoUserWidgetActions,
  editWidgetSource,
  getUserRemovedRules,
  getWidgetNames,
  getWidgetContent,
  getUserWidgetActions,
  populateWidgetState,
  populateEditWidgetModalData,
  performUserWidgetAction,
  readerParse,
  getPyqQuestions,
  mainsAnswerReview,
  questionsForDay,
  pay,
  paymentStatus,
  getReferralStats,
  getReferralCode,
  applyReferralCode,
  isLoggedIn,
  getPaymentAmount,
};
