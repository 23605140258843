import React, { useEffect, useState, useRef, useContext } from 'react';
import './index.css';
import ReactGA from "react-ga4";
import Box from "@mui/material/Box";
import LeftPane from "../LeftPane/LeftPane";
import AIFooter from '../AIFooter';
import AINavbar from '../AINavbar';
import StarIcon from '@mui/icons-material/Star';
import { yellow } from '@mui/material/colors';
import { Exam, FilePdf, ChartLineUp, SealPercent, Lock, CloudArrowUp, Trash, XCircle, TrashSimple } from "@phosphor-icons/react";
import InitialsImage from './InitialsImage'; 
import axios from 'axios';
import UserContext from "../../UserContext";
import { isLoggedIn, mainsAnswerReview} from "../../utils";
import LoginPrompt from "../LoginPrompt";
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MainsAnswer from "./MainsAnswer";
import Loaderb2c from './Loaderb2c';
import { useMediaQuery, createTheme, Grid } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import useAnalyticsEventTracker from "../../useAnalyticsEventTracker";
import DialogContentText from "@mui/material/DialogContentText";
import CloseIcon from "@mui/icons-material/Close";
import {
  CircularProgress,
  DialogActions,
  DialogContent,
  FormHelperText,
  IconButton,
} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import Footer from '../Footer/Footer';
import Stack from '@mui/material/Stack';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import OnScreenMenu from '../OnScreenMenu';
import {Link} from "react-router-dom";
import Modal from '../ModalImage';
import DOMPurify from 'dompurify';
import { Helmet } from "react-helmet";


function AIAnswerReviewB2CDashboard() {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [isScrolled, setIsScrolled] = useState(false);
  const theme = createTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const gaEventTracker = useAnalyticsEventTracker("ai-answer-review");
  const [userPlan, setUserPlan] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(true);
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  });




    //login prompt will open when no user logged in 
    const wrapperIsLoggedIn = async () => {
      let userData = await isLoggedIn();
      if (userData) {
        setLoggedInUserDetails(userData);
      } else {
        setOpenLoginPrompt(true);
      }
    }; 


      // Call the function to check if user is logged in
  useEffect(() => {
    wrapperIsLoggedIn();
    
    gaEventTracker(`${loggedInUserDetails.name ? loggedInUserDetails.name : "logged-out user"}/ai-answer-review`);
  }, []);

  //function used to check users plan to upload pdf 
  // useEffect(() => {
  //   console.log(loggedInUserDetails)
  //   if (loggedInUserDetails.plan==="zenith" && loggedInUserDetails.plan==="peak"){
  //     console.log("user can uplooad");
  //     setUserPlan(true);
  //   }
  // },[]);

  return(
    <>
      {isMobile ? (
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Mains Mentorship Program With Toppers | CollectorBabu</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2024, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/ai-answer-review"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <LeftPane Component={AIAnserReviewB2C} />
        <OnScreenMenu/>
    <Footer/>
    {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
        </>
      ):(  
        <>
        <Helmet>
            <title>UPSC Mains Answer Evaluation in 24 Hours – AI & Expert Reviewed | Mains Mentorship Program With Toppers | CollectorBabu</title>
            <meta
              name="description"
              content="Instant Mains Answer Evaluation for UPSC IAS Mains Exam - Providing AI-powered Answer Review for IAS aspirants. Get your UPSC IAS mains answers and full-length tests(FLTs) evaluated in just 24 hours with CollectorBabu's unique AI plus human review. Faster than traditional 7-day reviews, our service combines the precision of AI with the insight of experts. Join Mains Mentorship Program with Toppers. GS | Ethics | Essay | Optional all subjects covered."
            />
            <meta
              name="keywords"
              content="UPSC IAS Mains Exam, Mains 2024, AI Evaluation, AI Answer Evaluation, Answer Review Portal, Topper's Answers, Daily Answer Writing Initiative, Daily UPSC Mains Answer Writing Practice, Answer Review, UPSC Answer Writing Review, UPSC Answer Writing Practice, IAS Topper's Answers, Model Answers, Optional Answer Writing Practice, AI Answer Review, IAS Aspirants, Answer Writing Practice, CollectorBabu, UPSC Preparation, AI for UPSC, Secure Answer Writing, TLP, Abhyaas, Convert IAS, ConvertIAS, Mains Test Series, MGP, Expert Feedback, GS Answers, FLT Evaluation, AIR 167 IAS, AIR 309 IAS, Topper Answers UPSC, Rishabh Dev, Sociology Answer Writing, Anthropology Answer Writing, PSIR Answer Writing, 24-hour Feedback, FLT Evaluation, Mains Mentorship Program"
            />
            <meta
              property="og:title"
              content="Best Mains Answer Evaluation Platform | Full Test Evaluation in 24 hours | CollectorBabu"
            />
            <meta
              property="og:url"
              content="https://collectorbabu.com/ai-answer-review"
            />
            <meta
              property="og:description"
              content="Why wait a week? Get your UPSC mains answers evaluated in 24 hours by both AI and interview-appeared human experts at CollectorBabu. Get an instant AI-driven review of your answers for UPSC IAS Mains Exam."
            />
            <meta property="og:site_name" content="Collector Babu" />
        </Helmet>
        <AINavbar  />
        <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          
          <div style={{ marginTop: '80px' }}> 
            <AIAnserReviewB2C />
          </div>
        </Box>
        
        <OnScreenMenu/>
    <Footer/>
    {/* <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} /> */}
        </>
      )}
    </>
  )
};

const AIAnserReviewB2C = () => {
  const [loggedInUserDetails, setLoggedInUserDetails] = useContext(UserContext);
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [showImageUploadContent, setShowImageUploadContent] = useState(!loggedInUserDetails.user_id);
  const [showPdfUploadContent, setShowPdfUploadContent] = useState(!showImageUploadContent);
  const [images, setImages] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [openLoginPrompt, setOpenLoginPrompt] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [questionText, setQuestionText] = useState("");
  const [questionError, setQuestionError] = useState(false); 
  const [errorMessageB2c,setErrorMessageB2c] = useState("");
  const [manualAnswer, setManualAnswer] = useState("");
  const [multilePdfError,setMultiplePdfError] = useState("");
  const [modelAnswer, setModelAnswer] = useState("");
  const [showManualQuestionUpload,setshowManualQuestionUpload] = useState(true);
  const [isApiWorking,setIsApiWorking] = useState(false);
  const [showAnswer,setShowAnswer]=useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [userPlan, setUserPlan] = useState(false);
  const [pdfInfoMessageB2c,setPdfInfoMessageB2c]=useState("");
  const [pdfInfoMessage,setPdfInfoMessage]=useState(false);
  const [alreadyAttempted,setAlreadyAttempted]=useState(false);
  const [activeButton, setActiveButton] = useState(loggedInUserDetails.user_id ? 'pdf' : 'image');
  const [showOptional, setShowOptional] = useState(false);
  const [selectedOption, setSelectedOption] = useState('GS');
  const trackEvent = useAnalyticsEventTracker('AiAnswerReview Submit');
  let navigate = useNavigate();



  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const buttonStyle = (option) => ({
    backgroundColor: selectedOption === option ? 'blue' : '',
    color: selectedOption === option ? 'white' : '',
  });


  const handleOptionalClick = () => {
    setShowOptional(true);
    setSelectedOption('');
  };

  const handleBackClick = () => {
    setShowOptional(false);
    setSelectedOption('');
  };


  useEffect(() => {
    wrapperIsLoggedIn();
  }, []);

  const wrapperIsLoggedIn = async () => {
    let userData = await isLoggedIn();
    if (userData) {
      setLoggedInUserDetails(userData);
    }
  };

  const handleLoginPromptOpen = () => {
    setOpenLoginPrompt(true);
  };

  const handleLoginPromptClose = () => {
    setOpenLoginPrompt(false);
  };


  const handleImageClick = () => {
    setShowImageUploadContent(true);
    setShowPdfUploadContent(false);
    setActiveButton('image');
  };

  const handlePdfClick = () => {
    setShowImageUploadContent(false);
    setShowPdfUploadContent(true);
    setActiveButton('pdf');
  };

  useEffect(() => {
      if (loggedInUserDetails.user_id){
        setActiveButton('pdf');
        setShowPdfUploadContent(true);
        setShowImageUploadContent(false);
      }
      else{
        setActiveButton('image');
        setShowImageUploadContent(true);
        setShowPdfUploadContent(false);
      }
  },[loggedInUserDetails])
  
//correct one
  const handleFileChange = (event) => {
    if (!loggedInUserDetails.user_id) {
      handleLoginPromptOpen();
      setIsApiWorking(false);
      return;
    }

    const fileList = event.target.files;
    const newImages = [];
    const finalImages = [];

    for (let i = 0; i < Math.min(fileList.length, 3); i++) {
      const file = fileList[i];
      const formData = new FormData();
      finalImages.push(file);
      formData.append(`img_file${i + 1}`, file);
      newImages.push({ formData, previewURL: URL.createObjectURL(file) });
    }

    setImages((prevImages) => [...prevImages, ...newImages]);
    setImageFiles((prevImageFiles) => [...prevImageFiles, ...finalImages]);

    event.target.value = '';  
  };

    const handlePdfChange = (event) => {
      const fileList = event.target.files;
      const newPdfs = [];
    
      for (let i = 0; i < Math.min(fileList.length, 1); i++) {
        const file = fileList[i];
         if (file.type !== "application/pdf") {
        setErrorMessageB2c("Please upload a valid PDF file.");
        setQuestionError(true);
        setPdfFiles([]); // Clear the PDF files
        setTimeout(() => setQuestionError(false), 5000);
        return;
      }
        newPdfs.push(file);
      }
      setPdfFiles(newPdfs);
    };
  

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const fileList = event.dataTransfer.files;
    handleFileChange({ target: { files: fileList } });
  };


  const deleteImage = (index, event) => {
    const updatedImages = [...images];
    const updatedImageFiles = [...imageFiles];
  
    updatedImages.splice(index, 1);
    updatedImageFiles.splice(index, 1);
  
    setImages(updatedImages);
    setImageFiles(updatedImageFiles); 
  };
  
  
  const deletePdf = (index) => {
    const newPdfFiles = [...pdfFiles];
    newPdfFiles.splice(index, 1);
    setPdfFiles(newPdfFiles);
  };

  // const handlePdfFileChange = (e) => {

  //   const files = Array.from(e.target.files);
  //   setPdfFiles([...pdfFiles, ...files]);
  // };

  const handlePdfFileChange = (event) => {
    const files = Array.from(event.target.files);
    const validPdfs = files.filter(file => file.type === 'application/pdf');
  
    if (validPdfs.length !== files.length) {
      setPdfInfoMessageB2c('Only PDF files are allowed.');
      setPdfInfoMessage(true);
    }
  
    setPdfFiles(prevFiles => [...prevFiles, ...validPdfs]);
  };

  const handlePdfDragOver = (e) => {
    e.preventDefault();
  };

 // Function to handle PDF drop
const handlePdfDrop = (event) => {
  event.preventDefault();
  const fileList = event.dataTransfer.files;
  handlePdfChange({ target: { files: fileList } });
};



const handleSubmit = async () => {
  setIsApiWorking(true);
  trackEvent('Submit', 'AIAnswerReview submit');

  if (!loggedInUserDetails.user_id) {
    handleLoginPromptOpen();
    setIsApiWorking(false);
    return;
  }

  try {
    if (showPdfUploadContent) {
      if (pdfFiles.length === 0) {
        setErrorMessageB2c("Please upload the PDF.");
        setQuestionError(true);
        setTimeout(() => setQuestionError(false), 5000);
        setIsApiWorking(false);
        return;
      }

      const formData = new FormData();
      formData.append("mains_answer_sheet_pdf", pdfFiles[0]);

      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
        formData,
        {
          params: {
            user_id: loggedInUserDetails.user_id,
            subject: selectedOption,
            use_vision: false,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status === 200) {
        setPdfInfoMessage(true);
        setPdfInfoMessageB2c("Thanks! You will receive your evaluated PDF within 24 hours");
        setSnackbarMessage("Your PDF has been successfully uploaded.");
        setSnackbarSeverity("success");
        setTimeout(() => {
          setPdfFiles([]);
          setPdfInfoMessage(false);
        }, 5000);
      } else {
        throw new Error("Failed to upload the PDF");
      }
    } else if (showImageUploadContent) {
      if (questionText.trim().length === 0) {
        setErrorMessageB2c("Please enter a question.");
        setQuestionError(true);
        setTimeout(() => setQuestionError(false), 5000);
        setIsApiWorking(false);
        return;
      }

      if (images.length === 0) {
        setErrorMessageB2c("Please upload the images.");
        setQuestionError(true);
        setTimeout(() => setQuestionError(false), 5000);
        setIsApiWorking(false);
        return;
      }

      if (images.length > 3) {
        setSnackbarMessage("You can upload only 3 images.");
        setSnackbarSeverity("error");
        setSnackbarOpen(true);
        setIsApiWorking(false);
        return;
      }

      let tempQuestionId = "600000000000000000000000";
      const form = new FormData();

      imageFiles.forEach((file, index) => {
        form.append(`img_file${index + 1}`, file);
      });

      if (loggedInUserDetails.premium) {
        tempQuestionId = null;
      }

      const response = await mainsAnswerReview(
        form,
        loggedInUserDetails.user_id,
        "text",
        tempQuestionId,
        showManualQuestionUpload,
        questionText
      );

      if (response.data.status === "already_attempted") {
        setAlreadyAttempted(true);
        setSnackbarMessage("You have already attempted this.");
        setSnackbarSeverity("error");
      } else if (response.data.review) {
        setShowAnswer(true);
        setManualAnswer(response.data.review);
        setSnackbarMessage("Your images have been successfully uploaded.");
        setSnackbarSeverity("success");
      } else {
        throw new Error("Error uploading the images.");
      }
    }
  } catch (error) {
    console.error("Error submitting:", error);
    setSnackbarMessage("Error submitting the content.");
    setSnackbarSeverity("error");
  } finally {
    setSnackbarOpen(true);
    setIsApiWorking(false);
  }
};




//new handle submit error to resolve pdf uploading error
const handleMultipleSubmit = async () => {
  setIsApiWorking(true);

  try {
    for (let i = 0; i < pdfFiles.length; i++) {
      const formData = new FormData();
      formData.append("mains_answer_sheet_pdf", pdfFiles[i]);

      const response = await axios.post(
        "https://collectorbabu.com/api/mains/mains_save_answer_sheet_pdf",
        formData,
        {
          params: {
            user_id: loggedInUserDetails.user_id,
            subject: selectedOption,
            use_vision: false,
          },
          headers: {
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.status !== 200) {
        console.error("Error uploading PDF:", response.statusText);
        throw new Error("Error uploading PDF");
      }
    }

    setPdfInfoMessage(true);
    setPdfInfoMessageB2c("Thanks! You will receive your evaluated PDF within 24 hours");

    setTimeout(() => {
      setPdfInfoMessage(false);
      setPdfFiles([]);
    }, 5000);

    setSnackbarMessage("Your PDFs have been successfully uploaded.");
    setSnackbarSeverity("success");
    setSnackbarOpen(true);
  } catch (error) {
    console.error("Error submitting PDFs:", error);
    setSnackbarMessage("Failed to upload PDFs. Please try again.");
    setSnackbarSeverity("error");
    setSnackbarOpen(true);
  } finally {
    setIsApiWorking(false);
  }
};




  return (
  
    <>
        
        {isApiWorking && <Loaderb2c/>}
        <div className="main-container-b2c">
      <div className="upload-container-b2c">
          Upload your handwritten answer for instant evaluation
      </div>
      <div className='image-card-container-b2c'>
      <div className="icon-card-container-b2c">
        <div className="card-icon-container-b2c">
          <Exam className='image-card-icon-container-b2c' />
          <div className="mt-3 review-container-b2c-tr">Free Evaluation (upto 4 attempts)</div>
        </div>
        <div className="card-icon-container-b2c">
          <FilePdf className='image-card-icon-container-b2c' />
          <div className="mt-3 review-container-b2c-tr">
          Guaranteed 24-Hour Evaluation for Full-Length Tests and Essays
          </div>
        </div>
        <div className="card-icon-container-b2c">
        <ChartLineUp className='image-card-icon-container-b2c' />
          <div className="mt-3 review-container-b2c-tr">
          Personalized tracking
          </div>
        </div>
        <div className="card-icon-container-b2c">
        <SealPercent className='image-card-icon-container-b2c' />
          <div className="mt-3 review-container-b2c-tr">
            Purchase the pass at a special launch price for full access till
            Mains '24
          </div>
        </div>
      </div>
      </div>
      {/* <div className='image-pdf-upload-container'> */}
      <div className="toggle-button-container">
      <button
        className={`toggle-button ${activeButton === 'pdf' ? 'active' : ''}`}
        onClick={handlePdfClick}
      >
      <div className="pdf-button-b2c">
        <span>{loggedInUserDetails.plan==="trial" || loggedInUserDetails.plan==="zenith_pro" || loggedInUserDetails.plan==="zenith" || loggedInUserDetails.plan==="zenith_combo" || loggedInUserDetails.plan==="peak" ? "" : <Lock size={18} color="#fff" />}</span>
        <span>PDF</span>
        </div>
      </button>
      <button
        className={`toggle-button ${activeButton === 'image' ? 'active' : ''}`}
        onClick={handleImageClick}
      >
        Image
      </button>
</div>
  {showPdfUploadContent &&(
  <div className="options-list-container-b2c">
      {!showOptional ? (
        <Grid container spacing={2}>
        <Grid item>
          <Button
            style={{
              backgroundColor: selectedOption === 'GS' ? 'blue' : 'white',
              color: selectedOption === 'GS' ? 'white' : 'black',
            }}
            variant="contained"
            onClick={() => setSelectedOption('GS')}
          >
            GS 1/2/3
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor: selectedOption === 'GS4' ? 'blue' : 'white',
              color: selectedOption === 'GS4' ? 'white' : 'black',
            }}
            variant="contained"
            onClick={() => setSelectedOption('GS4')}
          >
            GS4
          </Button>
        </Grid>
        <Grid item>
          <Button
            style={{
              backgroundColor: selectedOption === 'ESSAY' ? 'blue' : 'white',
              color: selectedOption === 'ESSAY' ? 'white' : 'black',
            }}
            variant="contained"
            onClick={() => setSelectedOption('ESSAY')}
          >
            Essay
          </Button>
      </Grid>
        <Grid item>
          <Button
            style={{ backgroundColor: 'white', color: 'black' }}
            variant="contained"
            onClick={handleOptionalClick}
          >
            Optional
          </Button>
        </Grid>
      </Grid>
    ) : (
      <div className='max-w-xl'>
        <Button
          style={{ backgroundColor: 'white', color: 'black' }}
          variant="contained"
          startIcon={<ArrowBackIcon />}
          onClick={handleBackClick}
        >
          Back
        </Button>
        <Grid container spacing={2} style={{ marginTop: '10px'}}>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'SOCIOLOGY' ? 'blue' : 'white',
                color: selectedOption === 'SOCIOLOGY' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('SOCIOLOGY')}
            >
              Sociology
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'PSIR' ? 'blue' : 'white',
                color: selectedOption === 'PSIR' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('PSIR')}
            >
              PSIR
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'ANTHROPOLOGY' ? 'blue' : 'white',
                color: selectedOption === 'ANTHROPOLOGY' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('ANTHROPOLOGY')}
            >
              Anthropology
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'TELANGANAMOVEMENT' ? 'blue' : 'white',
                color: selectedOption === 'TELANGANAMOVEMENT' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('TELANGANAMOVEMENT')}
            >
              Telanganamovement
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'LAW' ? 'blue' : 'white',
                color: selectedOption === 'LAW' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('LAW')}
            >
              Law
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'HISTORY' ? 'blue' : 'white',
                color: selectedOption === 'HISTORY' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('HISTORY')}
            >
              History
            </Button>
          </Grid>
          <Grid item>
            <Button
              style={{
                backgroundColor: selectedOption === 'PSYCHOLOGY' ? 'blue' : 'white',
                color: selectedOption === 'PSYCHOLOGY' ? 'white' : 'black',
              }}
              variant="contained"
              onClick={() => setSelectedOption('PSYCHOLOGY')}
            >
              Psychology
            </Button>
          </Grid>
        </Grid>        
        </div>
      )}
    </div>
)}
      { showPdfUploadContent && (loggedInUserDetails.plan!=="trial" && loggedInUserDetails.plan!=="peak" && loggedInUserDetails.plan!=="zenith" && loggedInUserDetails.plan!=="zenith_combo" && loggedInUserDetails.plan!=="zenith_pro") && (
        <section className="premium-upload-container">
        <Lock size={32} color="#f52424" />
        <h3 className="premium-upload-title mb-2">Unlock with Zenith or Zenith Pro Plan</h3>

        <Button
            style={{}}
            variant="contained"
            onClick={(e) => navigate("/pricing")}
          >
            Buy Now!
          </Button>
        <h3 className="premium-upload-title mb-2">OR</h3>
          <Button
            style={{marginTop:'2px', backgroundColor: 'green', color:  'white'  }}
            variant="contained"
            onClick={(e) => navigate("/pricing")}
          >
            Try Zenith @ ₹99/-
          </Button>
        <p className="premium-upload-description">Best of Both - Human + AI Evaluation</p>
        <a  href="https://storage.googleapis.com/evaluated-answer-sheets/evaluated/64aefdb8ae077d7c1befedac_646e4b1a1f2d4aaf0d6a1ee1c2353fd1e0632e4806575aead9c6262f5ec3eb84.pdf" target="_blank" rel="noopener noreferrer">View Sample Evaluation</a>
      </section>
        )}


        {showImageUploadContent && (
  <div className="image-upload-container-b2c flex flex-col w-[650px] max-w-[520px]">
    <div className="input-field-container-b2c">
      <input placeholder='Enter your question here' className="input-field-b2c" onChange={(e) => setQuestionText(e.target.value)} type='text'/>
    </div>
    {questionError && (
      <div className="error-message-b2c">{errorMessageB2c}</div>
    )}
    <div className="flex flex-col px-6 py-4 mt-3 w-full bg-white rounded-xl border border-solid border-zinc-300 max-md:px-5 max-md:max-w-full">
      <div className="flex justify-center mb-2">
        <CloudArrowUp  size={32} color="#5c5c5c" />
      </div>
      <div className="flex gap-1" id="imageContainer" onDragOver={handleDragOver} onDrop={handleDrop}>
        {images.length === 0 && (
          <div className='flex justify-center w-100' onClick={() => document.getElementById('fileInput').click()}>
            <div className="font-semibold text-emerald-500 pr-1" >
              Click to upload 
            </div>
            <div className="text-slate-600">or drag and drop</div>
          </div>
        )}
        <input type="file" id="fileInput" accept="image/*" style={{ display: 'none' }} onChange={handleFileChange} />
        {images.map((image, index) => (
          <div key={image.previewURL} className="image-wrapper">
            <img src={image.previewURL} alt={`summa ${index + 1}`} className="uploaded-image" />
            <button onClick={() => deleteImage(index)} className="delete-button">
              <XCircle size={25} color="#fb3232" weight="fill" />
            </button>
            <div className="serial-number">{index + 1}</div>
          </div>
        ))}
        {images.length > 0 && images.length < 3 && (
          <div className="flex justify-center items-center w-full cursor-pointer" onClick={() => document.getElementById('fileInput').click()}>
             <div className="font-semibold text-emerald-500">
        {images.length === 1 ? "+ Click to add 2ⁿᵈ Page" : "+ Click to add 3ʳᵈ Page"}
    </div>
          </div>
        )}
      </div>
      <div className="mt-1 text-xs leading-5 text-center text-slate-600 max-md:max-w-full">
        Upload images (JPG/PNG) in the CORRECT sequence.
      </div>
    </div>
  </div>
)}

{showPdfUploadContent && (loggedInUserDetails.plan==="trial" || loggedInUserDetails.plan==="peak" || loggedInUserDetails.plan==="zenith" || loggedInUserDetails.plan==="zenith_pro" || loggedInUserDetails.plan==="zenith_combo") && (
  <>
    <div className="pdf-upload-container">
      {questionError && (
        <div className="error-message-b2c">{errorMessageB2c}</div>
      )}
      {pdfInfoMessage && (
        <div className="info-message-b2c">{pdfInfoMessageB2c}</div>
      )}
      <div className="upload-indicator">
        <CloudArrowUp size={32} color="#5c5c5c" />
      </div>

      <div className="file-container" id="pdfContainer" onDragOver={handlePdfDragOver} onDrop={handlePdfDrop}>
        {pdfFiles.length === 0 && (
          <div className='upload-info' onClick={() => document.getElementById('pdfInput').click()}>
            <div className="flex justify-center">
              <div className="upload-text">
                <span>Click to upload</span>
                <span>or drag and drop</span>
              </div>
            </div>
            <div className="mt-1 text-xs leading-5 text-center text-gray-400 max-md:max-w-full">
              You can upload one pdf
            </div>
          </div>
        )}
        {pdfFiles.map((pdf, index) => (
          <div key={index} className="pdf-list-container_">
            <div className="pdf-wrapper_">
              <p className="pdf-name_">{pdf.name}</p>
              <button onClick={() => deletePdf(index)} className="pdf-delete-button_">
                <Trash size={25} color="#fb3232" />
              </button>
            </div>
          </div>
        ))}

        <input type="file" id="pdfInput" accept=".pdf" style={{ display: 'none' }} multiple={loggedInUserDetails.user_id === "659662fb004103e6fd40aac4" || loggedInUserDetails.user_id==="66864f08ef7da0f98ef5ed22" || loggedInUserDetails.user_id === "64ba4fdf3572eafed73db968" || loggedInUserDetails.user_id === "648c663016aa3c84255ed879"} onChange={handlePdfFileChange} />
        
        {pdfFiles.length > 0 && (
    ["659662fb004103e6fd40aac4", "64ba4fdf3572eafed73db968", "648c663016aa3c84255ed879", "66864f08ef7da0f98ef5ed22"].includes(loggedInUserDetails.user_id) && (
        <button onClick={() => document.getElementById('pdfInput').click()} className="upload-more-button">
            Upload More PDFs
        </button>
    )
)}

      </div>
    </div>
  </>
)}


     {showAnswer ? " " : 
     <>
     {showPdfUploadContent && loggedInUserDetails.user_id === "659662fb004103e6fd40aac4" || loggedInUserDetails.user_id === "64ba4fdf3572eafed73db968" || loggedInUserDetails.user_id === "648c663016aa3c84255ed879" ? (
      <button onClick={handleMultipleSubmit} className="submit-button-b2c justify-center px-8 py-2 mt-6 text-base font-semibold text-white whitespace-nowrap bg-emerald-500 rounded max-md:px-5">
        Submit Multiple Pdf
      </button>
     ):
     (
      (!showPdfUploadContent || (loggedInUserDetails.plan==="trial" || loggedInUserDetails.plan==="peak" || loggedInUserDetails.plan==="zenith" || loggedInUserDetails.plan==="zenith_pro" || loggedInUserDetails.plan==="zenith_combo")) &&(

      <button onClick={handleSubmit} className="submit-button-b2c justify-center px-8 py-2 mt-6 text-base font-semibold text-white whitespace-nowrap bg-emerald-500 rounded max-md:px-5">
        Submit
      </button>
      ) 
      )
     }
     
      </>} 
    {/* </div> */}
    <div>
   {showAnswer && <MainsAnswer
                      mainsAnswer={manualAnswer}
                      showManualQuestionUpload={showManualQuestionUpload}
                      modelAnswer={modelAnswer}
                    />}

        {(showAnswer ||  pdfInfoMessage ) && 
        <div className="review-container-b2c-tr self-start mt-4 ml-5 text-xl font-extrabold tracking-wide leading-6 text-gray-900 uppercase max-md:max-w-full">
            <h1>Click to track your evaluations</h1>
        <Link to="/dashboard"><button className="dashboard-button-b2c justify-center px-8 py-2 mt-6 text-base font-semibold text-white whitespace-nowrap bg-emerald-500 rounded max-md:px-5">Dashboard</button></Link>
        </div>
         }            
    </div>
    </div>
    <div className="mobile-review-container-responsive flex flex-col items-start self-stretch p-20 max-md:px-5">
      <div className="review-container-b2c-tr self-start mt-4 ml-5 text-xl font-extrabold tracking-wide leading-6 text-gray-900 uppercase max-md:max-w-full">
        Trusted by candidates who appeared for Mains 2023
      </div>
      <div className="review-container-b2c-tr mt-2 ml-5 text-4xl font-semibold tracking-wider leading-10 text-gray-900 w-[600px] max-md:max-w-full">
        The Go-To Platform for Serious Aspirants: CollectorBabu
      </div>
      <div className="self-center mt-16 w-full max-w-[1240px] max-md:mt-10 max-md:max-w-full">
<div className="special-image-container">
<div className="special-image-wrapper">
  <img src="/images/testimonials/testimonial_7.jpg" alt="Special" className="special-uploaded-image" loading="lazy"/>
  <h1 className="special-serial-number">Rishabh Dev, AIR 167 CSE ‘23</h1>
</div>
<div className="special-image-wrapper">
  <img src="/images/testimonials/testimonial_8.jpg" alt="Special" className="special-uploaded-image-two" loading="lazy"/>
  <h1 className="special-serial-number">Anubhav, AIR 309 CSE ‘23</h1>
</div>
</div>
        <div className="flex gap-5 max-md:flex-col max-md:gap-0">
          <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col grow max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col px-6 pb-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                I wrote CSE-Mains 2023 and undoubtedly I can say that
                  Collector Babu helped me to fine tune my answer writing skills
                  with its Al Tool. I had always been apprehensive about the
                  level of precision that Al based tools can achieve especially
                  with handwritten answer scripts. However, the quality of
                  feedback, the scope of improvements clearly proved me wrong
                  and now I believe that CB's Al Tool will be a market disruptor
                  in this mundane Answer evaluation field where aspirants shell
                  out money and still are quite unsure whether they are guided
                  in the right direction.Hopefully, I get the the opportunity
                  to use your Al Mock interview Tool as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Nilesh" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Nilesh
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    APPEARED FOR INTERVIEW CSE ‘23
                    </div>
                  </div>
                </div>
              </div>
              {/* appeared for interview */}
              <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                I appeared in CSE Mains 2023 & CB's Al review for essay was
                  quite helpful during my preparation. Its feedback comments
                  were precisely on point, on which I could improve. This was
                  way better than the answer evaluation at institutes, that
                  comes at a huge cost yet with mediocre quality.I am immensely
                  grateful to the team for this innovative initiative. I hope CB
                  proves to be a game changer for me in CSE 2023 & guides
                  upcoming aspirants as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Chhaya Chaudhary" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Chhaya Chaudhary
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    APPEARED FOR MAINS ‘23
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
            <div className="flex flex-col self-stretch my-auto max-md:mt-10 max-md:max-w-full">
              <div className="flex flex-col p-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                The AI based feedback for a few answers and essay was very
                  good. I got to know how to make relevant introduction to
                  demand of question and not a general into that I used to do in
                  essay, it helped me in my week area that is conclusion and
                  also how to substantiate the ideas better and not just mention
                  for the sake of it.And not to mention the speed of checking
                  which is a sin qua non for last few days before exam Overall
                  it was a good experience to use Al for the first time .Thanks
                  for cooperation and support Both technical team and Harsh
                  helped me analyse the essay output in-person as well.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Manpreet Sharma" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Manpreet Sharma
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Interview CSE ‘23
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                  I found the CollectorBabu's answer evaluating tool useful
                  while preparing for this year's mains exam. It transformed the
                  way I approached answer writing.The tool provided me with
                  instant detailed feedback on my answers highlighting areas
                  where I needed improvement. Not only did it save me precious
                  time that would have been spent waiting for manual evaluation,
                  but it also gave me the confidence to tackle the exam with a
                  well-honed skillset.Thanks to the team for putting so much
                  effort to bring this wonderful initiative.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Darshan" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Darshan
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Mains ‘23
                    </div>
                  </div>
                </div>
              </div>
               {/* appeared for interview */}
              {/* <div className="flex flex-col p-6 mt-6 bg-white rounded-lg border border border-solid max-md:px-5 max-md:max-w-full">
                <div className="review-container-b2c-tr text-xl leading-8 text-gray-700 max-md:max-w-full">
                Highly recommended. Helped me write better answers.
                </div>
                <div className="flex gap-3 mt-8 max-md:flex-wrap">
                <InitialsImage name="Vaqilbabu" />
                  <div className="flex flex-col flex-1 max-md:max-w-full">
                    <div className="review-container-b2c-tr text-lg font-semibold text-gray-700 uppercase max-md:max-w-full">
                      Vaqilbabu
                    </div>
                    <div className="review-container-b2c-tr text-sm font-semibold text-gray-700 uppercase max-md:max-w-full">
                    Appeared for Mains ‘23
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="z-10 shrink-0 self-center mt-0 max-w-full h-[90px] w-[1240px] max-md:mt-0" />
    </div>
      {/* <AIFooter/> */}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} variant="filled" sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={alreadyAttempted}
        onClose={(e) => setAlreadyAttempted(false)}
        aria-labelledby="already-attempted-modal"
        aria-describedby="you have already attempted this question"
      >
        <DialogTitle id="already-attempted-modal">
          {"You have reached maximum free attempts for your account"}
        </DialogTitle>
        <IconButton
          onClick={(e) => setAlreadyAttempted(false)}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <DialogContentText id="already-attempted">
            Hundreds of aspirants are fast-pacing their preparation using
            CollectorBabu. Join them !!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={(e) => navigate("/pricing", { replace: true })}
          >
            Buy Now!
          </Button>
        </DialogActions>
      </Dialog>
      <LoginPrompt
        openLoginPrompt={openLoginPrompt}
        handleLoginPromptClose={handleLoginPromptClose}
      />
      </>
  );
}

export default AIAnswerReviewB2CDashboard;