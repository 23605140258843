import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import GetAppIcon from "@mui/icons-material/GetApp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from "@mui/material/TextField";
import CachedIcon from '@mui/icons-material/Cached';
import { classNames } from 'primereact/utils';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Tag } from 'primereact/tag';
import { Calendar } from 'primereact/calendar';
import { Card } from "primereact/card";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import 'primereact/resources/themes/saga-blue/theme.css';  // or other theme you prefer
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import {filterData} from '../../data/filterData.js'

const List = ({ loggedInUserDetails ,setPdf}) => {
  const navigate = useNavigate(); 
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loadingActions, setLoadingActions] = useState({});
  const [answerPdfData, setAnswerPdfData] = useState({ answer_sheet_status: [] });
  const [pdfUrl, setPdfUrl] = useState('');
  const [showPdf, setShowPdf] = useState(false);
  const [openBackdrop, setOpenBackDrop] = useState(false);
  const [status, setStatus] = useState([]);
  const [subject, setSubject] = useState([]);
  const loggedInUserDetails_user_id = loggedInUserDetails.user_id;
  const [filterApplied, setFilterApplied] = useState([]);
  const subjectFilter = filterData.subjectFilter;
  const statusFilter =  filterData.statusFilter;
  const handleCloseBackDrop = () => {
    setOpenBackDrop(false);
  };
  const handleOpenBackDrop = () => {
    setOpenBackDrop(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleEdit = async (pdf_url, pdf_name,pdf_hash) => {
    try {
      setPdf(pdf_url,pdf_name,pdf_hash);
    } catch (error) {
      console.error("Error editing PDF:", error);
    }
  };
  const getStatusStyle = (status) => {
    switch (status) {
      case "ERROR":
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
      case "UPLOADED":
        return {
          color: "blue", 
          backgroundColor: "rgba(0, 0, 255, 0.1)",
        };
      case "COMPLETED":
        return {
          color: "green",
          backgroundColor: "rgba(0, 128, 0, 0.151)",
        };
      case "IN_PROGRESS":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 165, 0, 0.1)",
        };
      case "REVIEW":
        return {
          color: "yellow",
          backgroundColor: "rgba(128, 128, 0, 0.5)",
        };
      default: 
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
    }
  };

  const getPdfStatusStyle = (status) => {
    switch (status) {
      case "Error":
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
      case "Uploaded":
        return {
          color: "blue", 
          backgroundColor: "rgba(0, 0, 255, 0.1)",
        };
      case "Completed":
        return {
          color: "green",
          backgroundColor: "rgba(0, 128, 0, 0.151)",
        };
      case "Uploading":
        return {
          color: "orange",
          backgroundColor: "rgba(255, 165, 0, 0.1)",
        };
      case "Review":
        return {
          color: "yellow",
          backgroundColor: "rgba(128, 128, 0, 0.5)",
        };
      default: 
        return {
          color: "red",
          backgroundColor: "rgba(255, 0, 0, 0.1)",
        };
    }
  };

  useEffect(() => {
    fetchData(); 
  }, [loggedInUserDetails]); 

  const fetchData = async () => {
    
    try {
      setLoading(true);
      const response = await axios.post(
        `https://collectorbabu.com/api/template/get_answer_sheet?user_id=${loggedInUserDetails_user_id}`,
        { user_id: loggedInUserDetails_user_id }
      );
      setAnswerPdfData({ answer_sheet_status: response.data || [] });
      console.log(answerPdfData);
    } catch (error) {
      console.error("Error fetching PDF list:", error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousPage = () => {
    setPage(prevPage => prevPage - 1);
  };

  const handleNextPage = () => {
    setPage(prevPage => prevPage + 1);
  };

  const [showPdfPopup, setShowPdfPopup] = useState(false);

  const openPdfPopup = () => {
    setShowPdfPopup(true);
  };

  const closePdfPopup = () => {
    setShowPdfPopup(false);
  };

  const handleStatusChange = async (id, newStatus) => {
    try {
      setLoadingActions((prevLoadingActions) => ({
        ...prevLoadingActions,
        [id]: true,
      }));
      await axios.post(
        "https://collectorbabu.com/api/template/change_answer_sheet_status",
        {
          user_id: loggedInUserDetails_user_id,
          mains_answer_sheet_hash: id,
          status: newStatus,
        }
      );
      fetchData();
    } catch (error) {
      console.error("Error changing PDF status:", error);
    } finally {
      setLoadingActions((prevLoadingActions) => ({
        ...prevLoadingActions,
        [id]: false,
      }));
    }
  };

  const filteredData = answerPdfData.answer_sheet_status.filter((row) => {
    const subjectMatch = subject.length === 0 || subject.includes(row.subject);
    const statusMatch = status.length === 0 || status.includes(row.status);
    return subjectMatch && statusMatch;
  });
  return (
    <>
    <div className="flex flex-col">
      <div className="listTitle">
        <div className="flex justify-between">
          <h1 className='table-text-latest'>Answer Sheets</h1>
          <div className="flex gap-1">
            <Button
              onClick={fetchData}
              sx={{
                backgroundColor: '#2196f3', 
                color: 'white', 
                '&:hover': {
                  backgroundColor: '#1976d2', 
                },
                marginLeft: '10px', 
              }}
            >
              Refresh
            </Button>
            <Button variant="outlined" style={{gap:1}} onClick={handleOpenBackDrop} startIcon={<FilterListIcon/>}>
              Filter
              {filterApplied.length != 0 && <span className="py-1 px-2 bg-blue-200 ml-1 rounded-full" >{filterApplied.length}</span>}
            </Button>
          </div>
        </div>
        <FilterComponent
        open={openBackdrop}
        onClose={handleCloseBackDrop} 
        subjectFilter={subjectFilter} 
        statusFilter={statusFilter} 
        setSubject={setSubject}
        setStatus={setStatus}
        setFilterApplied={setFilterApplied}
        />
     </div>
        <div>
          {filterApplied.length > 0 && (
              <>
              <div className="flex gap-1 my-1 ">
                {filterApplied.map((item, index) => (
                  <div key={index} className="flex border-2 w-auto rounded-lg">
                    <span className="bg-blue-200 p-1">{item.type}</span>
                    <span className="p-1">{item.value}</span>
                  </div>
                ))}
              </div>
              </>
            )}
        </div>
      </div> 
      <TableContainer component={Paper} className="table" style={{ overflow: 'auto', maxHeight: '400px' }}>
        <Table sx={{ minWidth: 750 }} aria-label="simple table">
          <TableHead>
            <TableRow className="tableRow">
              <TableCell className="tableCell">Tracking ID</TableCell>
              <TableCell className="tableCell">PDF Name</TableCell>
              <TableCell className="tableCell">Subject</TableCell>
              <TableCell className="tableCell">Date of Submission</TableCell>
              <TableCell className="tableCell">PDF Status</TableCell>
              <TableCell className="tableCell">Template Status</TableCell>
              <TableCell className="tableCell">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {answerPdfData.answer_sheet_status.length === 0 && (
              <div className="empty-list-message">
                <img  
                  className="empty-list-image"
                  src="https://i.pinimg.com/736x/ae/8a/c2/ae8ac2fa217d23aadcc913989fcc34a2.jpg" 
                  alt="empty-list"
                />
                <p className="empty-list-text">Upload PDF to get started</p>
              </div>
            )}
            {loading ? (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              // answerPdfData.answer_sheet_status.filter(row => row.pdf_name.toLowerCase().includes(searchTerm.toLowerCase())).map((row) => (
                filteredData.map((row) => (
                <TableRow key={row.mains_answer_sheet_hash} className="tableRowValues">
                  <TableCell className="tableCell">{row.mains_answer_sheet_hash.slice(-6)}</TableCell>
                  <TableCell className="tableCell" title={row.pdf_name}>
                  {row.raw_pdf_url ? (
                    <a 
                      href={row.raw_pdf_url} 
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {row.pdf_name.slice(0, 10)}
                    </a>
                  ) : (
                    row.pdf_name.slice(0, 10)
                  )}
                </TableCell>
                  <TableCell className="tableCell subjectNameCell" title={row.subject}>{row.subject.slice(0,4)}</TableCell>
                  <TableCell className="tableCell">{row.created_at.substring(0, 16).replace('T', ' ')}</TableCell>
                  <TableCell className="tableCell">
                    <span className="status" style={getPdfStatusStyle(row.status)}>
                      {row.status}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    <span className="status" style={getStatusStyle(row.template_status)}>
                      {row.template_status}
                    </span>
                  </TableCell>
                  <TableCell className="tableCell">
                    {loadingActions[row.mains_answer_sheet_hash] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        {row.raw_pdf_url ? (
                          <div className="actions-icons-r">
                            <select
                              value={row.template_status}
                              onChange={(e) =>
                                handleStatusChange(
                                  row.mains_answer_sheet_hash,
                                  e.target.value
                                )
                              }
                              className="status-dropdown"
                            >
                              <option value="UPLOADED">UPLOADED</option>
                              <option value="IN_PROGRESS">IN_PROGRESS</option>
                              <option value="COMPLETED">COMPLETED</option>
                              <option value="ERROR">ERROR</option>
                            </select>
                            <EditIcon
                              onClick={() => handleEdit(row.raw_pdf_url, row.pdf_name, row.mains_answer_sheet_hash)}
                              className="action-icon"
                            />
                          </div>
                        ) : (
                          <div style={{ textAlign: "center" }}><p>-</p></div>
                        )}
                      </>
                    )}
                  </TableCell>
                </TableRow>
              ))
            )}
            <div className="tableRowPagination">
              <TableRow style={{ height: 53 }}>
                <TableCell colSpan={5}>
                  <Button onClick={handlePreviousPage} disabled={page === 0}>Previous</Button>
                  <Button onClick={handleNextPage}>Next</Button>
                </TableCell>
              </TableRow>
            </div>
          </TableBody>
        </Table>
      </TableContainer>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled" sx={{ width: '100%' }}>
          Your pdf has been downloaded
        </Alert>
      </Snackbar>
    </>
  );
};
const FilterComponent = ({ open, onClose, subjectFilter, statusFilter, setSubject, setStatus, setFilterApplied}) => {
  
  const [selectedSubject, setSelectedSubject] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [onScreen, setOnScreen] = useState('');
  // const [statuses] = useState(['Error', 'Completed', 'Uploaded', 'Uploading', 'Review']);
  // const [subjects] = useState(['GS', 'GS4', 'ESSAY', 'SOCIOLOGY', 'ANTHROPOLOGY','TELANGANAMOVEMENT','LAW']);

  const handleTransactionStatusChange = (event) => {
    const value = event.target.name;
    setSelectedSubject((prev) =>
      prev.includes(value) ? prev.filter((status) => status !== value) : [...prev, value]
    );
  };

  const handlePaymentModeChange = (event) => {
    const value = event.target.name;
    setSelectedStatus((prev) =>
      prev.includes(value) ? prev.filter((mode) => mode !== value) : [...prev, value]
    );
  };

  const handleClearAll = () => {
    setSelectedSubject([]);
    setSelectedStatus([]);
  };

  const handleApply = () => {
    const appliedFilters = [];
    if (selectedSubject.length > 0) {
      appliedFilters.push(...selectedSubject.map(sub => ({ type: 'Subject', value: sub })));
      setSubject(selectedSubject);
    }
    if (selectedStatus.length > 0) {
      appliedFilters.push(...selectedStatus.map(stat => ({ type: 'Status', value: stat })));
      setStatus(selectedStatus);
    }
    setFilterApplied(appliedFilters);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setSubject([]);
      setStatus([]);
      // setSelectedSubject([]);
      // setSelectedStatus([]);
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} className="min-h-3/4" maxWidth="md" fullWidth>
      <DialogTitle className="border-b-2">
        Filters
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
      <div className="flex w-full">
        <div className="flex flex-col w-[24%] mt-4 gap-3 px-3">
        <Button
          size="large"
          onClick={() => setOnScreen("subject")}
          style={{
            backgroundColor: onScreen === "subject"? "lightblue" : "white",
            color: onScreen === "subject"? "blue" : "black",
          }}
        >
          Subject
        </Button>

        <Button
          size="large"
          onClick={() => setOnScreen("status")}
          style={{
            backgroundColor: onScreen === "status"? "lightblue" : "white",
            color: onScreen === "status"? "blue" : "black",
          }}
        >
          Status
        </Button>
        </div>
        <div className="flex-1 border-l-2 p-4" style={{ minHeight: '400px' }}>
            {onScreen === 'subject' && (
              <>
             <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedSubject.length === subjectFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedSubject(subjectFilter);
                      } else {
                        setSelectedSubject([]);
                      }
                    }}
                    name="selectAll"
                  />
                }
                label="Select All"
                style={{
                  color: selectedSubject.length === subjectFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {subjectFilter.map((sub) => (
                  <FormControlLabel
                    key={sub}
                    control={
                      <Checkbox
                        checked={selectedSubject.includes(sub)}
                        onChange={handleTransactionStatusChange}
                        name={sub}
                      />
                    }
                    label={sub}
                    style={{
                      border: selectedSubject.includes(sub)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedSubject.includes(sub) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
            {onScreen === 'status' && (
              <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedStatus.length === statusFilter.length}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedStatus(statusFilter);
                      } else {
                        setSelectedStatus([]);
                      }
                    }}
                    name="selectAll"
                  />
                }
                label="Select All"
                style={{
                  color: selectedStatus.length === statusFilter.length ? 'blue' : 'black',
                  borderRadius: '8px',
                  padding: '10px',
                }}
              />
              <FormGroup style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '10px' }}>
                {statusFilter.map((stat) => (
                  <FormControlLabel
                    key={stat}
                    control={
                      <Checkbox
                        checked={selectedStatus.includes(stat)}
                        onChange={handlePaymentModeChange}
                        name={stat}
                      />
                    }
                    label={stat}
                    style={{
                      border: selectedStatus.includes(stat)? '2px solid blue' : '2px solid lightgrey',
                      color: selectedStatus.includes(stat) ? 'blue' : 'black',
                      borderRadius: '8px',
                      padding: '10px',
                    }}
                  />
                ))}
              </FormGroup>
              </>
            )}
          </div>
      </div>
      </DialogContent>
      <DialogActions className="border-t-2">
        <Button onClick={handleClearAll}>Clear All Filters</Button>
        <Button onClick={handleApply} variant="contained" color="primary">
          Apply
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default List;
